<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div class="caja" id="section1">
            <div class="caja-inner-margin">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="titulo">
                            <img alt="prelog/logo" src="{{ config.app.assets.logoLarge }}" class="landing-logo" />
                            <h1>La plataforma educativa que facilita el trabajo al profesor de educación básica</h1>
                            <h2>Accede a cientos de recursos, crea evaluaciones y corrígelas en segundos</h2>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <login-usuario [enableContactUs]="false"></login-usuario>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
