import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    supportMail: string
    contactPhone: string
    config: typeof config
    constructor() {
        this.config = config

        this.supportMail = config.plataforma.info.soporte.mail
        this.contactPhone = config.plataforma.info.contacto.telefono || ""
    }
}
