import { PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { estadisticasRouting } from "./admin_estadisticas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AdminLayoutsModule,
        EstadisticasModule,
        EstablecimientosModule,
        estadisticasRouting,
        EnsayosModule,
        SharedModule,
        PuntajeEstadisticasModule
    ],
    declarations: [],
    exports: []
})
export class AdminEstadisticasModule {}
