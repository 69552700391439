import { PuntajeInstrumentosModule, PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { TabsModule } from "ngx-bootstrap"

import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { InstrumentoPredefinidosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { routing } from "./admin_instrumentos.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        AdminLayoutsModule,
        InstrumentosModule,
        InstrumentoPredefinidosModule,
        EnsayosModule,
        TabsModule,
        routing,
        PuntajeInstrumentosModule,
        PuntajeEnsayosModule
    ],
    exports: [],
    declarations: []
})
export class AdminInstrumentosModule {}
