<footer class="print-hide">
    <div class="clearfix">
        <div class="first-col footer-col company-info">
            <a href="http://ogr.cl/">
                <img
                    class="footer-logo"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                    alt="{{ config.plataforma.info.companyName }}"
                />
            </a>
            <p>
                Todos los Derechos Reservados a "Open Green Road S.A." ®
                <br />
            </p>
        </div>

        <!--<div class="footer-col">
			<div class="speechbubble">
				<p  class="txt-company-footer">Para mayor información comercial y/o subscripción al servicio para colegios:
				</p>
				<p class="info-bullet">
					<span class="icono telefono"><fa [name]="'phone'"></fa></span>
					<span class="info">{{contactPhone}}</span>
				</p>
				<p class="info-bullet">
					<span class="icono email"><fa [name]="'envelope'"></fa></span>
					<span class="info">{{supportMail}}</span>
				</p>
			</div>
		</div>-->
    </div>
</footer>
